@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: AntonioBold;
    src: url(Antonio-Bold.ttf);
}

.map-background {
    border-radius: 20;
}

.logo-background {
    border-radius: 5;
    box-shadow: '0px 0px 18px 5px rgba(0,0,0,0.12)';
    letter-spacing: 3px;
}

.partnership-badge {
    font-family: 'AntonioBold', sans-serif;
    font-style: italic;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
}

.mobile-priority {
    font-family: 'AntonioBold', sans-serif;
    font-style: italic;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
}

.clickable-group {
    pointer-events: bounding-box;
    opacity: 0.85;
}

.clickable-group:hover {
    cursor: pointer !important;
    opacity: 1.0;
}

.hover-pointer:hover {
    cursor: pointer !important;
}

.heavy-text {
    font: bold 12px sans-serif;
}

.coursor-to-pointer:hover {
    cursor: pointer !important;
    opacity: 1.0;
}

.important-text {
    font-size: 16px;
}

.glowing-image {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}

.map_gradient_color_1 {
    stop-color: rgba(182, 223, 248, 1);
}

.map_gradient_color_2 {
    stop-color: white;
}